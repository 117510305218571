import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Row, Container } from 'reactstrap';
import Img from "gatsby-image"
import FsLightbox from 'fslightbox-react';
import SEO from "../components/seo"

import "../styles/_gallery.scss"

export default function Test({ data }) {
  const [lightboxController, setLightboxController] = 
    React.useState({
      toggler: false,
      slide: 1
    });
      
  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
  });
  }

  const Images = data.allContentfulImmagineGalleria.edges.map(({node}, index) => {
    return (
      <Col md="4" className="mb-5">
        <div className="imgContainer" onClick={()=>openLightboxOnSlide(index+1)}>
          <Img className="shadow-lg rounded" fluid={node.foto.fluid} alt={`slide ${index+1}`}/>
          <div className="overlay">
            {/*<div className={GalleryStyles.text}>Clicca per ingrandire</div> */}
          </div>
        </div>
      </Col>
    )
  })

  const img = data.allContentfulImmagineGalleria.edges.map(({node}, index) => {
    return (node.foto.fluid.src)
  })

  return (
    <Layout>
      <SEO title="Galleria" keywords={[`auto`, `riparazioni`, `pitstop`]} />

      <div>
        <Container className="mt-5">
          <Row>
            { Images }
          </Row>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={img}
            slide={lightboxController.slide}
          />
        </Container>
      </div>   
    </Layout>
  )
  }

  export const query = graphql`
  {
    allContentfulImmagineGalleria(sort: {fields: foto___createdAt}) {
      edges {
        node {
          foto {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`